/* IMPORT CINEMAS FROM JSON */
const cinemasJSON = import('./cinemas.json', {
  assert: {
    type: 'json',
  },
});

const cinemas = [];

cinemasJSON.then((d) =>
  d.forEach((cinema) => {
    cinemas.push(cinema);
  })
);

/** FUNCTION MOBILE MENU **/
const hamburgerBtn = document.querySelector('[data-js="hamburgerBtn"]');
const menuMobile = document.querySelector('[data-js="headerNav"]');
const closeBtn = document.getElementById('closeMenu');
const sideDots = document.querySelectorAll('.side-dots__nav-link');
const headerLink = document.querySelectorAll('.header__nav__list-link');
let spools = document.querySelectorAll('.spool__item--bg');
let windowOnStart = window.innerWidth;

// reload window if resize
window.addEventListener('resize', () => {
  if (
    (windowOnStart > 1024 && window.innerWidth < 1024) ||
    (windowOnStart < 1024 && window.innerWidth > 1024)
  ) {
    location.reload(true);
  }
});

hamburgerBtn.addEventListener('click', function () {
  if (this.classList.contains('is-active')) {
    this.classList.remove('is-active');
  } else {
    this.classList.add('is-active');
  }
});

// add listener to navlinks in order to hide menu
for (var i = 0; i < headerLink.length; i++) {
  headerLink[i].addEventListener('click', function () {
    menuMobile.style.display = 'none';
    hamburgerBtn.style.display = 'block';
  });
}

/** TIMER v. 2.0 **/
function updateTimer(deadline) {
  var time = deadline - new Date();
  return {
    days: Math.floor(time / (1000 * 60 * 60 * 24)),
    hours: Math.floor((time / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((time / 1000 / 60) % 60),
    seconds: Math.floor((time / 1000) % 60),
    total: time,
  };
}

// function animateClock(span){
//   span.classList.add("turn");
//   setTimeout(function(){
//     span.classList.remove("turn");
//   },700);
// }

function startTimer(id, deadline) {
  const timerInterval = setInterval(function () {
    const clock = document.getElementById(id);
    const timer = updateTimer(deadline);

    // check for end of timer
    if (timer.total < 1) {
      clearInterval(timerInterval);
      timer.days = 0;
      timer.hours = 0;
      timer.minutes = 0;
      timer.seconds = 0;
    }

    clock.innerHTML =
      '<div class="timer__remaining__cont"><span class="timer__remaining__text">Dni</span><span class="timer__remaining__numbers">' +
      (timer.days + 100).toString().substring(1) +
      '</span></div>' +
      '<div class="timer__remaining__cont"><span class="timer__remaining__text">Godzin</span><span class="timer__remaining__numbers">' +
      (timer.hours + 100).toString().substring(1) +
      '</span></div>' +
      '<div class="timer__remaining__cont"><span class="timer__remaining__text">Minut</span><span class="timer__remaining__numbers">' +
      (timer.minutes + 100).toString().substring(1) +
      '</span></div>' +
      '<div class="timer__remaining__cont"><span class="timer__remaining__text">Sekund</span><span class="timer__remaining__numbers">' +
      (timer.seconds + 100).toString().substring(1) +
      '</span></div>';

    // animations
    var spans = clock.getElementsByClassName('timer__remaining__numbers');
    // animateClock(spans[3]);
    // if(timer.seconds == 59) animateClock(spans[2]);
    // if(timer.minutes == 59 && timer.seconds == 59) animateClock(spans[1]);
    // if(timer.hours == 23 && timer.minutes == 59 && timer.seconds == 59) animateClock(spans[0]);
  }, 1000);
}

// window.onload = function(){
const deadline = new Date('September 28, 2024 00:00:00');
startTimer('clock', deadline);
//   };

/** FUNCTION FORM **/
const SendBtnNl = document.getElementById('send-newsletterForm');
const SendBtnCF = document.getElementById('send-cinema-form');
const sendBtnCnt = document.getElementById('send-contact-form');

if (SendBtnCF) {
  SendBtnCF.addEventListener('click', function (e) {
    e.preventDefault();
    SendForm('cinemaForm');
  });
}

if (SendBtnNl) {
  SendBtnNl.addEventListener('click', function (e) {
    e.preventDefault();
    SendForm('newsletterForm');
  });
}

if (sendBtnCnt) {
  sendBtnCnt.addEventListener('click', function (e) {
    e.preventDefault();
    SendForm('contactForm');
  });
}

const cinemaInputs = document.querySelectorAll('[data-cinema-input]'),
  cinemaFormValidCheck = document.querySelector('[data-cf-valid-check]'),
  cinemaFormEmail = document.querySelector('[data-cf-email]'),
  cinemaFormMsg = document.querySelector('[data-cf-msg]'),
  cinemaFormNumbers = document.querySelectorAll('[data-cf-nr]');
const contactInputs = document.querySelectorAll('[data-contact-input]'),
  contactFormValidCheck = document.querySelector('[data-contact-valid-check]'),
  contactFormEmail = document.querySelector('[data-contact-email]'),
  contactFormMsg = document.querySelector('[data-contact-msg]');
const newsletterInputs = document.querySelectorAll('[data-newsletter-email]'),
  newsletterFormMsg = document.querySelector('[data-newsletter-msg]'),
  newsletterFormValidCheck = document.querySelector(
    '[data-newsletter-valid-check]'
  );

function SendForm(formId) {
  const formElem = document.getElementById(formId);
  if (formElem.checkValidity()) {
    const formData = {};
    const formInputs = [].slice.call(formElem.elements);

    formInputs.forEach((el) => {
      if (el.getAttribute('type') !== 'checkbox' && el.tagName === 'INPUT') {
        formData[el.getAttribute('name')] = el.value;
      }
    });

    const XHR = new XMLHttpRequest();

    if (formData) {
      if (formId == 'newsletterForm') {
        XHR.open('POST', '/api/forms/newsletter', true);
      } else if (formId == 'cinemaForm') {
        XHR.open('POST', '/api/forms/independentcinema', true);
      } else {
        XHR.open('POST', '/api/forms/contact', true);
      }
      XHR.setRequestHeader('Content-Type', 'application/json');
      XHR.onreadystatechange = function (aEvt) {
        if (XHR.readyState == 4) {
          if (XHR.status == 200) {
            // console.log(XHR.responseText);

            if (formId == 'cinemaForm') {
              cinemaFormMsg.innerHTML =
                'Formularz wysłany poprawnie. Dziękujemy, skontaktujemy sie wkrótce!';
              cinemaFormMsg.style.color = '#2c8c88';
              cinemaFormValidCheck.classList.remove('is-active');
            } else if (formId == 'newsletterForm') {
              newsletterFormMsg.innerHTML =
                'Formularz wysłany poprawnie. Dziękujemy!';
              newsletterFormMsg.style.color = '#2c8c88';
              newsletterFormValidCheck.classList.remove('is-active');
            } else {
              contactFormMsg.innerHTML =
                'Formularz wysłany poprawnie. Dziękujemy, skontaktujemy sie wkrótce!';
              contactFormMsg.style.color = '#2c8c88';
              contactFormValidCheck.classList.remove('is-active');
            }

            formElem.reset();
            if (formElem.querySelector('input[type="checkbox"]')) {
              formElem.querySelector('input[type="checkbox"]').checked = false;
              // formElem.querySelector('.checked').classList.remove('checked');
            }
          } else {
            // console.log("Błąd podczas ładowania strony");

            if (formId == 'cinemaForm') {
              cinemaFormMsg.innerHTML =
                'Formularz nie został wysłany. Wystąpił błąd.';
              cinemaFormMsg.style.color = '#ff5076';
              cinemaFormValidCheck.classList.remove('is-active');
            } else if (formId == 'newsletterForm') {
              newsletterFormMsg.innerHTML =
                'Formularz nie został wysłany. Wystąpił błąd.';
              newsletterFormMsg.style.color = '#ff5076';
              newsletterFormValidCheck.classList.remove('is-active');
            } else {
              contactFormMsg.innerHTML =
                'Formularz nie został wysłany. Wystąpił błąd.';
              contactFormMsg.style.color = '#ff5076';
              contactFormValidCheck.classList.remove('is-active');
            }

            formElem.reset();
            if (formElem.querySelector('input[type="checkbox"]')) {
              formElem.querySelector('input[type="checkbox"]').checked = false;
              // formElem.querySelector('.checked').classList.remove('checked');
            }
          }
        }
      };
    }

    XHR.send(JSON.stringify(formData));
  } else {
    if (formId == 'cinemaForm') {
      cinemaFormValidCheck.classList.add('is-active');

      for (let val of cinemaInputs) {
        val.style.border = '1px solid #ff5076';

        val.onkeyup = function () {
          if (this.checkValidity()) {
            this.style.border = '1px #8f8ba4 solid';
          } else {
            this.style.border = '1px solid #ff5076';
          }
        };

        if (val.value === '') {
          cinemaFormMsg.innerHTML = 'Uzupełnij poprawnie wszystkie pola';
          val.style.border = '1px solid #ff5076';
        } else {
          val.style.border = '1px #8f8ba4 solid';

          if (cinemaFormNumbers[0].checkValidity() === false) {
            cinemaFormMsg.innerHTML = 'Podaj poprawny numer domu';
            cinemaFormNumbers[0].style.border = '1px solid #ff5076';
          } else if (cinemaFormNumbers[1].checkValidity() === false) {
            cinemaFormMsg.innerHTML = 'Podaj poprawny kod pocztowy';
            cinemaFormNumbers[1].style.border = '1px solid #ff5076';
          } else if (cinemaFormNumbers[2].checkValidity() === false) {
            cinemaFormMsg.innerHTML = 'Podaj poprawny numer telefonu';
            cinemaFormNumbers[2].style.border = '1px solid #ff5076';
          }

          if (cinemaFormEmail.checkValidity() === false) {
            cinemaFormMsg.innerHTML = 'Podaj poprawny adres email';
            cinemaFormEmail.style.border = '1px solid #ff5076';
          } else {
            cinemaFormEmail.style.border = '1px #8f8ba4 solid';
          }

          if (
            cinemaFormValidCheck.querySelector('input').checkValidity() ===
            false
          ) {
            cinemaFormMsg.innerHTML = 'Zaznacz zgodę';
          }
        }
      }
    } else if (formId == 'newsletterForm') {
      newsletterFormValidCheck.classList.add('is-active');

      for (let val of newsletterInputs) {
        val.style.border = '1px solid #ff5076';

        val.onkeyup = function () {
          if (this.checkValidity()) {
            this.style.border = '1px #8f8ba4 solid';
          } else {
            this.style.border = '1px solid #ff5076';
          }
        };

        if (val.value === '') {
          newsletterFormMsg.innerHTML = 'Uzupełnij poprawnie wszystkie pola';
          val.style.border = '1px solid #ff5076';
        } else {
          val.style.border = '1px #8f8ba4 solid';

          if (newsletterFormNumbers[0].checkValidity() === false) {
            newsletterFormMsg.innerHTML = 'Podaj poprawny numer domu';
            newsletterFormNumbers[0].style.border = '1px solid #ff5076';
          } else if (cinemaFormNumbers[1].checkValidity() === false) {
            newsletterFormMsg.innerHTML = 'Podaj poprawny kod pocztowy';
            newsletterFormNumbers[1].style.border = '1px solid #ff5076';
          } else if (cinemaFormNumbers[2].checkValidity() === false) {
            newsletterFormMsg.innerHTML = 'Podaj poprawny numer telefonu';
            newsletterFormNumbers[2].style.border = '1px solid #ff5076';
          }

          if (newsletterFormEmail.checkValidity() === false) {
            newsletterFormMsg.innerHTML = 'Podaj poprawny adres email';
            newsletterFormEmail.style.border = '1px solid #ff5076';
          } else {
            newsletterFormEmail.style.border = '1px #8f8ba4 solid';
          }

          if (
            newsletterFormValidCheck.querySelector('input').checkValidity() ===
            false
          ) {
            newsletterFormMsg.innerHTML = 'Zaznacz zgodę';
          }
        }
      }
    } else {
      contactFormValidCheck.classList.add('is-active');

      for (let val of contactInputs) {
        val.style.border = '1px solid #ff5076';

        val.onkeyup = function () {
          if (this.checkValidity()) {
            this.style.border = '1px #8f8ba4 solid';
          } else {
            this.style.border = '1px solid #ff5076';
          }
        };

        if (val.value === '') {
          contactFormMsg.innerHTML = 'Uzupełnij poprawnie wszystkie pola';
          val.style.border = '1px solid #ff5076';
        } else {
          val.style.border = '1px #8f8ba4 solid';

          if (contactFormEmail.checkValidity() === false) {
            contactFormMsg.innerHTML = 'Podaj poprawny adres email';
            contactFormEmail.style.border = '1px solid #ff5076';
          } else {
            contactFormEmail.style.border = '1px #8f8ba4 solid';
          }

          if (
            contactFormValidCheck.querySelector('input').checkValidity() ===
            false
          ) {
            contactFormMsg.innerHTML = 'Zaznacz zgodę';
          }
        }
      }
    }
  }
}

/** FUNCTION CHECKBOX CHECKING **/

// var checkboxForm = document.getElementById('agreements');
// var checkboxForm2 = document.getElementById('agreementsNL');
// var checkboxForm3 = document.getElementById('agreementsCtc');

// var checkboxFormLabel = document.querySelector('.cinema-form__form__checkbox-label');
// var checkboxFormLabel2 = document.querySelector('.about__newsletter__checkbox-label');
// var checkboxFormLabel3 = document.querySelector('.contact__form__checkbox-label');

// checkboxFormLabel.addEventListener('click', function(e) {
//     e.preventDefault();
//     if (checkboxFormLabel.classList.contains('checked')) {
//         checkboxForm.checked = false;
//         checkboxFormLabel.classList.remove('checked');
//     }
//     else {
//         checkboxForm.checked = true;
//         checkboxFormLabel.classList.add('checked');
//     }
// });
// checkboxFormLabel2.addEventListener('click', function(e) {
//     e.preventDefault();
//     if (checkboxFormLabel2.classList.contains('checked')) {
//         checkboxForm2.checked = false;
//         checkboxFormLabel2.classList.remove('checked');
//     }
//     else {
//         checkboxForm2.checked = true;
//         checkboxFormLabel2.classList.add('checked');
//     }
// });
// checkboxFormLabel3.addEventListener('click', function(e) {
//     e.preventDefault();
//     if (checkboxFormLabel3.classList.contains('checked')) {
//         checkboxForm3.checked = false;
//         checkboxFormLabel3.classList.remove('checked');
//     }
//     else {
//         checkboxForm3.checked = true;
//         checkboxFormLabel3.classList.add('checked');
//     }
// });

/** AUTOMATIC GEOLOCATION **/
var centerObj = {lat: 52.237049, lng: 21.017532};
if ('geolocation' in navigator) {
  // check if geolocation is supported/enabled on current browser
  navigator.geolocation.getCurrentPosition(
    function success(position) {
      centerObj = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    },
    function error(error_message) {
      // for when getting location results in an error
      console.error(
        'An error has occured while retrieving location',
        error_message
      );
    }
  );
} else {
  // when geolocation is not supported
  console.log('geolocation is not enabled on this browser');
}

/** GOOGLE MAP **/
let markers;
window.initMap = function () {
  window.map = new google.maps.Map(document.getElementById('googleMap'), {
    center: centerObj,
    zoom: 13,
    gestureHandling: 'cooperative',
    styles: [
      {
        elementType: 'geometry',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        elementType: 'labels.icon',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          {
            color: '#f5f5f5',
          },
        ],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#000000',
          },
          {
            weight: 1.5,
          },
        ],
      },
      {
        featureType: 'poi',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          {
            color: '#ffffff',
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
          {
            color: '#cfcfcf',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.text',
        stylers: [
          {
            color: '#000000',
          },
          {
            saturation: 5,
          },
          {
            visibility: 'on',
          },
        ],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.stroke',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#757575',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
          {
            color: '#dadada',
          },
        ],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#616161',
          },
        ],
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
      {
        featureType: 'transit',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
      {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
          {
            color: '#e5e5e5',
          },
        ],
      },
      {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
          {
            color: '#eeeeee',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
          {
            color: '#c9c9c9',
          },
        ],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
          {
            color: '#9e9e9e',
          },
        ],
      },
    ],
  });

  var currentInfoWindow = null;

  //adding markers with automatical implementation of clustering
  markers = cinemas.map(function (cinemas, i) {
    var myIcon;
    //trzeba ręcznie przerzucić graficzki do dista
    switch (cinemas.type) {
      case 'CinemaCity':
        myIcon = '/m_cc.png';
        break;
      case 'Multikino':
        myIcon = '/m_mk.png';
        break;
      case 'C3D':
        myIcon = '/m_c3d.png';
        break;
      case 'Helios':
        myIcon = '/m_h.png';
        break;
      case 'Kino':
        myIcon = '/m_k.png';
        break;
    }

    var cinemaMarker = new google.maps.Marker({
      position: {lat: cinemas.lat, lng: cinemas.lng},
      map: window.map,
      icon: myIcon,
    });

    const infowindow = new google.maps.InfoWindow({
      content: `<div class="info-window">
            <p><strong>${cinemas.name}</strong></p>
            <p>${cinemas.address}</p>
            <p><strong>Bilety kupisz na:</strong> 
            <a href=${cinemas.link} style="color:green;"}>LINK</a>
            </p>
          </div>`,
    });

    google.maps.event.addListener(cinemaMarker, 'click', function () {
      if (currentInfoWindow != null) {
        currentInfoWindow.close();
      }

      infowindow.open(map, cinemaMarker);
      currentInfoWindow = infowindow;
    });
  });

  // markerCluster = new MarkerClusterer(window.map, markers,
  // {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
};

// get the city to the centerObj;
var searchText;
var searchContainer = document.getElementById('searchContainer');
var searchResult = [];
var searchInput = document.getElementById('mapSearch');
function onLoad(response) {
  var responseObj = response.srcElement.response;
  searchText = responseObj;
  compareNames(searchText);
}

function onError(e) {
  searchText = 'Warszawa';
  compareNames(searchText);
}

var ipReq = new XMLHttpRequest();
ipReq.open('GET', 'https://api.ipify.org?format=json');
ipReq.onload = onIpLoad;
ipReq.onerror = onIpError;
ipReq.send(null);

function onIpLoad(response) {
  var respIp = JSON.parse(response.srcElement.response);
  console.log(respIp);
  var respondedIp = respIp.ip;
  var req = new XMLHttpRequest();
  req.open('GET', 'https://ipapi.co/' + respondedIp + '/city/', true);
  req.onload = onLoad;
  req.onerror = onError;
  req.send(null);
}

function onIpError() {
  alert(
    'Przepraszamy, chwilowo nie jesteśmy w stanie określić automatycznie Twojej lokalizacji.'
  );
}

/** SEARCH ENGINE FOR THE MAP **/
searchInput.addEventListener('keyup', function () {
  searchText = searchInput.value;
  searchResult = [];
  searchContainer.innerHTML = '';
  compareNames(searchText);
});

/** FUNCTION COMPARE TO SEARCH QUERY **/
function compareNames(searchText) {
  for (var i = 0; i < cinemas.length; i++) {
    let city = cinemas[i].city;
    city = city.toLowerCase();
    let text = searchText.toLowerCase();
    
    if (city.includes(text)) {
      searchResult.push(cinemas[i]);
    }
  }
  searchResult.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    } else {
      return 1;
    }
  });
  if (searchResult.length) {
    listItems(searchResult);
  }
}

/** FUNCTION TO GET THE CINEMAS LIST DISPLAYED (FIRST SELECTED) **/
function listItems(resultArray) {
  for (var y = 0; y < searchResult.length; y++) {
    if (searchContainer.childElementCount < resultArray.length) {
      const cinLink = document.createElement('div');
      const cinName = document.createElement('p');
      const cinAdr = document.createElement('p');
      const cinImg = document.createElement('div');

      cinAdr.setAttribute('class', 'map__search-link__adress');
      cinName.innerText = resultArray[y].name;

      cinName.setAttribute('class', 'map__search-link__name');
      cinAdr.innerText = resultArray[y].address;

      cinImg.classList.add('map__search-link__img');

      switch (resultArray[y].type) {
        case 'Multikino': {
          cinImg.classList.add('map__search-link__img--mk');
          break;
        }
        case 'Helios': {
          cinImg.classList.add('map__search-link__img--hel');
          break;
        }
        case 'C3D': {
          cinImg.classList.add('map__search-link__img--c3d');
          break;
        }
        case 'CinemaCity': {
          cinImg.classList.add('map__search-link__img--cc');
          break;
        }
        case 'Kino': {
          cinImg.classList.add('map__search-link__img--k');
          break;
        }
      }
      cinName.setAttribute('class', 'map__search-link__name');
      cinLink.appendChild(cinImg);
      cinLink.appendChild(cinName);
      cinLink.appendChild(cinAdr);
      cinLink.setAttribute('class', 'map__search-link');
      cinLink.classList.add(y);
      cinLink.setAttribute(
        'onclick',
        'window.centerToItem(' +
          resultArray[y].lat +
          ',' +
          resultArray[y].lng +
          ',' +
          y +
          ')'
      );
      searchContainer.appendChild(cinLink);
    }
  }
  var timeoutClick = setTimeout(function () {
    searchContainer.firstElementChild.click();
  }, 1000);
}

/**  FUNCTION TO MOVE BY CLICKING ON DIVS WITH CINEMA NAMES **/
window.centerToItem = function (lat, lng, number) {
  window.map.setCenter({lat: lat, lng: lng});
  var links = document.querySelectorAll('.map__search-link');
  for (var i = 0; i < links.length; i++) {
    if (links[i].classList.contains(number)) {
      links[i].classList.add('selected');
    } else if (links[i].classList.contains('selected')) {
      links[i].classList.remove('selected');
    }
  }
};

/**  ADD SELECTED ON HASH CHANGE **/
window.addEventListener('hashchange', function () {
  var secName = window.location.hash;
  secName = secName.slice(1, secName.length);
  for (var i = 0; i < headerLink.length; i++) {
    if (headerLink[i].getAttribute('href') == '#' + secName) {
      headerLink[i].classList.add('selected');
    } else {
      if (headerLink[i].classList.contains('selected')) {
        headerLink[i].classList.remove('selected');
      }
    }
  }
  for (var i = 0; i < sideDots.length; i++) {
    if (sideDots[i].getAttribute('href') == '#' + secName) {
      sideDots[i].classList.add('selected');
    } else {
      if (sideDots[i].classList.contains('selected')) {
        sideDots[i].classList.remove('selected');
      }
    }
  }
});

const loadAnimations = (() => {
  return {
    isInViewport: (elem, nr) => {
      const distance = elem[nr].getBoundingClientRect();

      return (
        (distance.top >= 0 &&
          distance.bottom <= elem[nr].offsetHeight + 1000) ||
        (distance.bottom >= 100 && distance.bottom <= distance.height)
      );
    },

    addToObserve: (element) => {
      if (element) {
        for (var m = 0; m < element.length; m++) {
          var thisNr = m;

          if (loadAnimations.isInViewport(element, thisNr)) {
            element[thisNr].classList.add('is-in-viewport');
          }
        }
      }
    },

    loadEvents: () => {
      const animationElements = document.querySelectorAll('[data-js=anim-el]'),
        modules = [animationElements];
      for (const k in modules) {
        loadAnimations.addToObserve(modules[k]);
      }

      window.onscroll = () => {
        for (const k in modules) {
          loadAnimations.addToObserve(modules[k]);
        }
      };
    },
  };
})();

document.documentElement.classList.add('js');
const loaderWrapper = document.querySelector('[data-loader]');
const loader = document.querySelector('.lds-ellipsis');

function fadeOut(el) {
  var el = el.style;
  el.opacity = 1;
  (function fade() {
    (el.opacity -= 0.1) < 0 ? (el.display = 'none') : setTimeout(fade, 40);
  })();
}

const hideLoader = new Promise((r, j) => {
  fadeOut(loader);

  setTimeout(() => {
    fadeOut(loaderWrapper);
  }, 1000);
});

window.onload = () => {
  if (window.innerWidth <= 1025) {
    if (
      !document
        .querySelector('.m-header')
        .classList.contains('m-header--scrolled')
    ) {
      document.querySelector('.m-header').classList.add('m-header--scrolled');
    }
    // let layer = document.querySelector('.c-layer--main-p');
    // layer.style.top = (layer.offsetWidth / 2) + 300 + 'px';
    // layer.style.left = '-12%';
  }

  //   if (window.innerWidth >= 1200) {
  //     menu();
  //   } else {
  //     mobileMenu();
  //   }

  hideLoader.then(
    setTimeout(() => {
      loadAnimations.loadEvents();
    }, 1000)
  );
};

const setLayerProps = () => {
  if (window.innerWidth <= 1025) {
    // let layer = document.querySelector('.c-layer--main-p');
    // layer.style.height = layer.offsetWidth + 'px';
    // window.onresize = () => {
    //     layer.style.height = layer.offsetWidth + 'px';
    //     layer.style.top = (layer.offsetWidth / 2) + 300 + 'px';
    // }
    // console.log(layer.offsetTop)
  }
};

setLayerProps();

/**  SWIPER **/
// const swiperContainer = document.querySelector('.swiper-container');
// const headerLogo = document.querySelector('.m-header__brand');
// const slideDownArrow = document.querySelector('[data-slide-down]');

// if (window.innerWidth >= 1025) {
//   var fullPageSwiper = new Swiper('.swiper-container', {
//     speed: 700,
//     direction: 'vertical',
//     mousewheel: true,
//     pagination: {
//       el: '[data-nav-list]',
//       type: 'bullets',
//       clickable: true,
//       renderBullet: function (index, className) {
//         return '<li class="' + 'side-dots__nav-item ' + className + '"></li>';
//       },
//     },
//     on: {
//       init: function () {
//         let swiper = this;

//         document.body.className = 'slide-' + swiper.activeIndex;
//       },
//       slideChange: function () {
//         let swiper = this;
//         // let layerP = document.querySelector('.c-layer--cinema-p');
//         // let layerR = document.querySelector('.c-layer--cinema-r');
//         // let layerY = document.querySelector('.c-layer--cinema-y');

//         document.body.className = 'slide-' + swiper.activeIndex;

//         if (swiper.activeIndex !== 0) {
//           slideDownArrow.classList.add('is-hidden');
//         } else {
//           slideDownArrow.classList.remove('is-hidden');
//         }

//         // if (swiper.activeIndex === 2) {
//         //     layerP.classList.add('is-active');
//         //     layerR.classList.add('is-active');
//         //     layerY.classList.add('is-active');
//         //     layerP.classList.remove('is-active-2');
//         //     layerR.classList.remove('is-active-2');
//         //     layerY.classList.remove('is-active-2');
//         // } else if (swiper.activeIndex === 3) {
//         //     layerP.classList.add('is-active-2');
//         //     layerR.classList.add('is-active-2');
//         //     layerY.classList.remove('is-active');
//         //     setTimeout(function() {
//         //         layerY.classList.add('is-active-2');
//         //     }, 500)
//         // } else {
//         //     layerP.classList.remove('is-active');
//         //     layerP.classList.remove('is-active-2');
//         //     layerR.classList.remove('is-active');
//         //     layerY.classList.remove('is-active');
//         //     layerR.classList.remove('is-active-2');
//         //     layerY.classList.remove('is-active-2');
//         // }
//         spools.forEach((e) => {
//           e.style.transform = `rotate(${swiper.translate / 20}deg)`;
//         });
//         // if(player.getPlayerState() === 1){
//         //   player.pauseVideo()
//         // }
//       },
//     },
//   });
//   fullPageSwiper.allowTouchMove = false;
//   slideDownArrow.onclick = () => {
//     fullPageSwiper.slideNext();
//   };
// } else {
//   slideDownArrow.onclick = () => {
//     document.querySelector('.m-header').classList.add('m-header--scrolled');
//     swiperContainer.classList.add('mob-hero-hidden');
//     swiperContainer.classList.remove('mob-hero-visible');
//   };

//   headerLogo.onclick = () => {
//     document.querySelector('.m-header').classList.remove('m-header--scrolled');
//     swiperContainer.classList.add('mob-hero-visible');
//     swiperContainer.classList.remove('mob-hero-hidden');
//   };
// }

/** MENU SWIPER */

// const menu = () => {
//   let swiperSlides = document.querySelectorAll('[data-nav-index]');
//   let logo = document.querySelector('[data-scroll-top]');

//   logo.onclick = () => {
//     window.scrollTo(0, 0);

//     if (window.innerWidth >= 1025) {
//       fullPageSwiper.slideTo(0);
//     }
//   };

//   for (let [index, val] of swiperSlides.entries()) {
//     val.onclick = () => {
//       fullPageSwiper.slideTo(index + 2);
//     };
//   }
// };

/** MOBILE MENU SWIPER */

// const mobileMenu = () => {
//   let swiperSlides = document.querySelectorAll('[data-nav-index]');
//   let swiperSlidesElems = document.querySelectorAll('.swiper-slide');
//   let mobileMenuPanel = document.querySelector('[data-js="hamburgerBtn"]');

//   for (let [index, val] of swiperSlides.entries()) {
//     val.onclick = () => {
//       mobileMenuPanel.classList.remove('is-active');
//       swiperContainer.classList.add('mob-hero-hidden');
//       swiperContainer.classList.remove('mob-hero-visible');

//       swiperSlidesElems[index + 2].scrollIntoView({
//         block: 'start',
//         behavior: 'auto',
//       });
//     };
//   }
// };

/** MOBILE MENU WITHOUT SWEEPER **/
const hideMenuMobileOnClick = () => {
  let menuLinks = document.querySelectorAll('[data-nav-index]');
  let mobileMenuPanel = document.querySelector('[data-js="hamburgerBtn"]');

  if (!menuLinks && !mobileMenuPanel) return;

  if (window.innerWidth < 1200) {
    menuLinks.forEach((link) => {
      link.addEventListener('click', function () {
        if (mobileMenuPanel.classList.contains('is-active')) {
          setTimeout(() => {
            mobileMenuPanel.classList.remove('is-active');
          }, 500);
        }
      });
    });
  }
};

hideMenuMobileOnClick();

/**  BACKGROUND SPOOLS ANIMATION **/
if (window.innerWidth < 1025) {
  window.addEventListener('scroll', function (e) {
    spools.forEach((e) => {
      e.style.transform = `rotate(${window.scrollY / 5}deg)`;
    });
  });
}

/**  VIDEO HERO **/

// var heroVid = document.querySelector('[data-video]');
// if (window.innerWidth >= 1200) {
//     var playPromise = heroVid.play();

//     if (playPromise !== undefined) {
//         playPromise
//     }

//     swiperContainer.classList.remove('mob-hero-hidden');
//     swiperContainer.classList.remove('mob-hero-visible');

// } else {
//     // heroVid.pause();

//     swiperContainer.classList.add('mob-hero-visible');
//     swiperContainer.classList.remove('mob-hero-hidden');
// }

/**  VIDEO SECTION **/

// var tag = document.createElement("script");
// tag.src = "https://www.youtube.com/iframe_api";

// var firstScriptTag = document.getElementsByTagName("script")[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// var mArticleItem = document.querySelector("#video"),
// 	playBtn = document.querySelector('[data-js="playBtn"]'),
// 	videoId = mArticleItem.getAttribute("data-vid-id"),
// 	videoImg = mArticleItem.querySelector("img");
// videoImg.setAttribute(
// 	"src",
// 	"https://img.youtube.com/vi/PexmSKzSG40/maxresdefault.jpg"
// );

// var player;
// window.onYouTubePlayerAPIReady = function () {
// 	player = new YT.Player("player", {
// 		playerVars: {
// 			listType: "playlist",
// 			list: videoId,
// 			controls: "0",
// 			playerVars: { rel: 0 },
// 		},
// 	});
// };

// playBtn.onclick = function () {
// 	var parent = this.parentElement.parentElement,
// 		cl = parent.getAttribute("class");
// 	parent.classList.remove("is-video-active");

// 	if (cl === "video__bg-img") {
// 		parent.classList.add("is-video-active");
// 		player.playVideo();
// 	} else {
// 		parent.classList.remove("is-video-active");
// 	}
// };
